<template>
  <div id="app" v-bind:class="{greater:w1,less:!w1}" >
    <div class="menu">
      <div class="container headDiv">
        <div><span style="color: white;font-weight: bold; font-size: large;">Jelly Pudding&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
        <router-link v-bind:class="{choosen:v1,unchoosen:!v1}" to="/" @click.native="clickMe(1)">Home</router-link>
        <router-link v-bind:class="{choosen:v2,unchoosen:!v2}" to="/product" @click.native="clickMe(2)">Product</router-link>
        <router-link v-bind:class="{choosen:v3,unchoosen:!v3}" to="/contact" @click.native="clickMe(3)">Contact us</router-link>
      </div>
    </div>

    <div class="container contentDiv">
      <router-view></router-view>
    </div>

    <div id="footer" v-bind:class="{footer:true,fmargin:f,margin2:!f}" >
      <p>Copyright &copy; 2010-2024 Jelly Pudding All Rights Reserved</p>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        v1:true,
        v2:false,
        v3:false,
        w1:true,
        f:true
      }
    },
    created() {
        this.checkWindowWidth();
    },
    methods: {
      checkWindowWidth: function() {
        // var width = document.documentElement.clientWidth ;
        var width = screen.width ;
        console.log(width);
        if (width<1920) {
          this.w1 = false ;
        }
        else  {
          this.w1 = true ;
        }
        if (width<=1080) {
          this.f = false ;
        }
        else {
          this.f = true ;
        }
      },
      clickMe: function (value) {
        console.log(document.documentElement.clientWidth);
        console.log("----------------"+value)
        if (value==1) {
          this.v1 = true ;
          this.v2 = false ;
          this.v3 = false ;
        }
        else if (value==2) {
          this.v1 = false ;
          this.v2 = true ;
          this.v3 = false ;
        }
        else if (value==3) {
          this.v1 = false ;
          this.v2 = false ;
          this.v3 = true ;
        }
      }
    },
  }
</script>

<style lang="less">
    body {
      margin: 0;
      display: flex;
      justify-content: center;
    }
    .greater{
      width: 1920px;
      /*width: 100%;*/
    }
    .less{
      width: 100%;
    }
    .container {
      width: 100%;
    }
    .contentDiv{
      /*margin-top: -50px;*/
    }
    .menu {
      background-color: rgba(0, 0, 0, 0.2);
      position: fixed;
      height: 80px;
      width: inherit;
      display: flex;
    }
    .headDiv {
      padding-left: 20%;
      display: flex;
      align-items: center;
    }
    .menu a {
      text-decoration: none;
      padding: 0 10px;

    }
    .ig {
      margin-top: 35px;
      display: flex;
      justify-content: center; /* 水平居中 */
    }
    .margin2 {
      margin-top: 15px;
    }
    .phone {
      margin-top: 15px;
      display: flex;
      justify-content: center; /* 水平居中 */
    }
    .choosen {
      color: #FF5F00;
      font-weight: bold;
    }
    .unchoosen{
      color: white;
    }
    .fmargin{
      margin-top: 50px;
    }
    .footer {
      background-color: #d6d3d3;
      color: #fff;
      text-align: center;
      bottom: 0;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

</style>
