<template>
  <div><div ><img alt='' style='width: 100%;' :src='imageUrl30' ></div>
    <div :class='cls'><img alt='' style='width: 68%;' :src='imageUrl3' ></div></div>
</template>

<script>

export default {
  name: 'Contact',
  data: function(){
    return {
      // imageUrl30:'http://192.168.0.192:8081/image/getImage/chead2.png',
      // imageUrl3:'http://192.168.0.192:8081/image/getImage/contact12.png',
      imageUrl30:'http://44.201.239.38/ace/image/getImage/chead2.png',
      imageUrl3:'http://44.201.239.38/ace/image/getImage/contact12.png',
      cls:'ig',
      cls1:'prod'
    }
  },
  created() {
    this.checkWinWidth();
  },
  methods: {
    checkWinWidth: function() {
      var width = screen.width ;
      console.log(width);
      if (width<=1080) {
        // this.imageUrl30 = 'http://192.168.0.192:8081/image/getImage/chead1.png' ;
        // this.imageUrl3 = 'http://192.168.0.192:8081/image/getImage/contact11.png';
        this.imageUrl30 = 'http://44.201.239.38/ace/image/getImage/chead1.png' ;
        this.imageUrl3 = 'http://44.201.239.38/ace/image/getImage/contact11.png';
        this.cls = "phone";
      }
      else  {
        // this.imageUrl30 = 'http://192.168.0.192:8081/image/getImage/chead2.png' ;
        // this.imageUrl3 = 'http://192.168.0.192:8081/image/getImage/contact12.png';
        this.imageUrl30 = 'http://44.201.239.38/ace/image/getImage/chead2.png' ;
        this.imageUrl3 = 'http://44.201.239.38/ace/image/getImage/contact12.png';
        this.cls = "ig";
      }

    },
  }
}
</script>
