<template>
  <div><div ><img alt='' style='width: 100%;' :src='imageUrl20' ></div>
    <div :class='cls'><img alt='' style='width: 61%;' :src='imageUrl21' ></div>
    <div :class='cls'><img alt='' style='width: 100%;' :src='imageUrl22' ></div>
    <div ><img alt='' style='width: 100%;' :src='imageUrl23' ></div></div>
</template>

<script>

export default {
  name: 'Product',
  data: function(){
    return {
      // imageUrl20:'http://192.168.0.192:8081/image/getImage/phead2.png',
      // imageUrl21:'http://192.168.0.192:8081/image/getImage/product12.png',
      // imageUrl22:'http://192.168.0.192:8081/image/getImage/product22.png',
      // imageUrl23:'http://192.168.0.192:8081/image/getImage/product32.png',
      imageUrl20:'http://44.201.239.38/ace/image/getImage/phead2.png',
      imageUrl21:'http://44.201.239.38/ace/image/getImage/product12.png',
      imageUrl22:'http://44.201.239.38/ace/image/getImage/product22.png',
      imageUrl23:'http://44.201.239.38/ace/image/getImage/product32.png',
      cls:'ig',
    }
  },
  created() {
    this.checkWinWidth();
  },
  methods: {
    checkWinWidth: function() {
      var width = screen.width ;
      console.log(width);
      if (width<=1080) {
        // this.imageUrl20 = 'http://192.168.0.192:8081/image/getImage/phead1.png' ;
        // this.imageUrl21 = 'http://192.168.0.192:8081/image/getImage/product11.png';
        // this.imageUrl22 = 'http://192.168.0.192:8081/image/getImage/product21.png';
        // this.imageUrl23 = 'http://192.168.0.192:8081/image/getImage/product31.png';
        this.imageUrl20 = 'http://44.201.239.38/ace/image/getImage/phead1.png' ;
        this.imageUrl21 = 'http://44.201.239.38/ace/image/getImage/product11.png';
        this.imageUrl22 = 'http://44.201.239.38/ace/image/getImage/product21.png';
        this.imageUrl23 = 'http://44.201.239.38/ace/image/getImage/product31.png';
        this.cls = "phone";
      }
      else  {
        // this.imageUrl20 = 'http://192.168.0.192:8081/image/getImage/phead2.png' ;
        // this.imageUrl21 = 'http://192.168.0.192:8081/image/getImage/product12.png';
        // this.imageUrl22 = 'http://192.168.0.192:8081/image/getImage/product22.png';
        // this.imageUrl23 = 'http://192.168.0.192:8081/image/getImage/product32.png';
        this.imageUrl20 = 'http://44.201.239.38/ace/image/getImage/phead2.png' ;
        this.imageUrl21 = 'http://44.201.239.38/ace/image/getImage/product12.png';
        this.imageUrl22 = 'http://44.201.239.38/ace/image/getImage/product22.png';
        this.imageUrl23 = 'http://44.201.239.38/ace/image/getImage/product32.png';
        this.cls = "ig";
      }

    },
  }
}
</script>
