<template>
  <div><div><img style='max-width: 100%; object-fit: scale-down;' alt='' :src='imageUrl10' ></div>
    <div :class='cls'><img style='width: 45%;object-fit: scale-down' alt='' :src='imageUrl11' ></div>
    <div :class='cls'><img style='max-width: 100%;object-fit: scale-down;' alt='' :src='imageUrl12' ></div>
    <div :class='cls'><img style='width: 55%;object-fit: scale-down' alt='' :src='imageUrl13' ></div></div>
</template>

<script>

export default {
  name: 'Home',
  data: function(){
    return {
      // imageUrl10:'http://192.168.0.192:8081/image/getImage/hhead2.png',
      // imageUrl11:'http://192.168.0.192:8081/image/getImage/home12.png',
      // imageUrl12:'http://192.168.0.192:8081/image/getImage/home22.png',
      // imageUrl13:'http://192.168.0.192:8081/image/getImage/home32.png',
      imageUrl10:'http://44.201.239.38/ace/image/getImage/hhead2.png',
      imageUrl11:'http://44.201.239.38/ace/image/getImage/home12.png',
      imageUrl12:'http://44.201.239.38/ace/image/getImage/home22.png',
      imageUrl13:'http://44.201.239.38/ace/image/getImage/home32.png',
      cls:'ig',
    }
  },
  created() {
    this.checkWinWidth();
  },
  methods: {
    checkWinWidth: function() {
      var width = screen.width ;
      console.log(width);
      if (width<=1080) {
            // this.imageUrl10 = 'http://192.168.0.192:8081/image/getImage/hhead1.png' ;
            // this.imageUrl11 = 'http://192.168.0.192:8081/image/getImage/home11.png';
            // this.imageUrl12 = 'http://192.168.0.192:8081/image/getImage/home21.png';
            // this.imageUrl13 = 'http://192.168.0.192:8081/image/getImage/home31.png';
            this.imageUrl10 = 'http://44.201.239.38/ace/image/getImage/hhead1.png' ;
            this.imageUrl11 = 'http://44.201.239.38/ace/image/getImage/home11.png';
            this.imageUrl12 = 'http://44.201.239.38/ace/image/getImage/home21.png';
            this.imageUrl13 = 'http://44.201.239.38/ace/image/getImage/home31.png';
            this.cls = "phone";
      }
      else  {
            // this.imageUrl10 = 'http://192.168.0.192:8081/image/getImage/hhead2.png' ;
            // this.imageUrl11 = 'http://192.168.0.192:8081/image/getImage/home12.png';
            // this.imageUrl12 = 'http://192.168.0.192:8081/image/getImage/home22.png';
            // this.imageUrl13 = 'http://192.168.0.192:8081/image/getImage/home32.png';
            this.imageUrl10 = 'http://44.201.239.38/ace/image/getImage/hhead2.png' ;
            this.imageUrl11 = 'http://44.201.239.38/ace/image/getImage/home12.png';
            this.imageUrl12 = 'http://44.201.239.38/ace/image/getImage/home22.png';
            this.imageUrl13 = 'http://44.201.239.38/ace/image/getImage/home32.png';
            this.cls = "ig";
      }

    },
  }
}
</script>
