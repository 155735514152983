import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Product from '../views/Product.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)


const  routes= [
    { path: '/', component: Home, name: 'home' },
    { path: '/product', component: Product, name: 'product' },
    { path: '/contact', component: Contact, name: 'contact' },
  ]

const router = new VueRouter({
  routes
})

export default router
